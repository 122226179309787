<template>
  <section class="return-policy" data-nosnippet>
    <div class="return-policy-container">
    <div>
      <router-link class="router-link" to="/">
          <div class="footer-logo-container">
            <div class="footer-brand-image"></div>
          </div>
      </router-link>
    </div>
    <div class="return-policy-content">
      <div class="return-policy-title title-text highlighted-text">Widerrufsbelehrung</div>
        <p><strong>Widerrufsrecht</strong></p> 
        <p>Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gr&uuml;nden diesen Vertrag zu widerrufen. Die Widerrufsfrist betr&auml;gt vierzehn Tage ab dem Tag an dem Sie oder ein von Ihnen benannter Dritter, der nicht der Beförderer ist, die letzte Ware in Besitz genommen haben bzw. hat.<br></p>
        <p>Um Ihr Widerrufsrecht auszu&uuml;ben, senden Sie die original versiegelte Ware zusammen mit dem ausgefüllten Widerrufsformular an die nachfolgende Adresse zurück. <br><br>Pleasmile UG (haftungsbeschränkt)<br>Steinring 56<br>44789 Bochum<br>Deutschland<br></p><p>Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Ware zusammen mit dem ausgefüllten Widerrufsformular vor Ablauf der Widerrufsfrist absenden.</p>
        <p>Bereits geöffnete (entsiegelte) Pleasbits-Verpackungen können aus Gründen des Gesundheitsschutzes und der Hygiene nicht zurückgegeben werden und sind somit von dem Widerrufsrecht ausgeschlossen.</p>
        <a class="external-link" href="https://pleasmile.com/assets/files/pleasmile_widerruf_v1.pdf" target="_blank">Widerrufsformular öffnen</a><br><br>
        <p><strong>Folgen des Widerrufs </strong> <p>Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschlie&szlig;lich der Lieferkosten (mit Ausnahme der zus&auml;tzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, g&uuml;nstigste Standardlieferung gew&auml;hlt haben), unverz&uuml;glich und sp&auml;testens binnen vierzehn Tagen ab dem Tag zur&uuml;ckzuzahlen, an dem die Mitteilung &uuml;ber Ihren Widerruf dieses Vertrags bei uns eingegangen ist. F&uuml;r diese R&uuml;ckzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der urspr&uuml;nglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdr&uuml;cklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser R&uuml;ckzahlung Entgelte berechnet. Wir können die Rückzahlung verweigern, bis wir die Waren wieder zurückerhalten haben oder bis Sie den Nachweis erbracht haben, dass Sie die Waren zurückgesandt haben, je nachdem, welches der frühere Zeitpunkt ist.<br> </p> <p>Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen vierzehn Tagen ab dem Tag, an dem Sie uns über den Widerruf dieses Vertrags unterrichten, an uns zurückzusenden oder zu übergeben. Die Frist ist gewahrt, wenn Sie die Waren vor Ablauf der Frist von vierzehn Tagen absenden.  Sie tragen die unmittelbaren Kosten der R&uuml;cksendung der Waren. Sie m&uuml;ssen f&uuml;r einen etwaigen Wertverlust der Waren nur aufkommen, wenn dieser Wertverlust auf einen zur Pr&uuml;fung der Beschaffenheit, Eigenschaften und Funktionsweise der Waren nicht notwendigen Umgang mit ihnen zur&uuml;ckzuf&uuml;hren ist. </p>
        <p>Diese Widerrufsbelehrung basiert auf dem <a class="external-link" target="_blank" title="Trusted Shops" href="https://legal.trustedshops.com/">Trusted Shops</a> Rechtstexter in Kooperation mit <a target="_blank" title="FÖHLISCH Rechtsanwälte" class="external-link" href="https://foehlisch.com" rel="nofollow">FÖHLISCH Rechtsanwälte</a>. Es wurden individuelle Anpassungen durch Pleasmile vorgenommen.</p>
      </div>
      <Footer />
    </div>
  </section>
</template>

<script>
import Footer from "@/components/Footer.vue";

export default {
  name: "ReturnPolicy",
  components: {
    Footer,
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/colors.scss";

.return-policy {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background-color: $page-background-color-primary-dark;
}

.return-policy-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
}

.return-policy-content {
  font-family: Dosis;
  font-weight: 400;
  font-size: 18px;
  color: $text-color-primary-light;
  max-width: 600px;
  margin: 40px 40px 40px 40px;
}

.return-policy-title {
  display: inline-block;
  margin-bottom: 40px;
}
</style>
